export const footerImages = [
  {
    link: 'https://toya.net.pl/strona/grupatoya',
    img: '/grupa_toya.svg',
    alt: 'Grupa Toya',
  },
  {
    link: 'https://toya.net.pl/',
    img: '/Toya.svg',
    alt: 'Toya',
  },
  {
    link: 'http://tvtoya.pl/',
    img: '/TVToya.svg',
    alt: 'Toya TV',
  },
  {
    link: 'http://toyastudios.pl/',
    img: '/toya_studios.svg',
    alt: 'Toya Studios',
  },
  {
    link: 'https://www.wytwornia.pl/',
    img: '/wytwornia.svg',
    alt: 'Wytwórnia',
  },
  {
    link: 'https://www.doubletreelodz.pl/',
    img: '/DoubleTreeHilton.svg',
    alt: 'Double Tree Hilton',
  },
  {
    link: '/f/projekty_ue',
    img: '/UE_EFRR-04.svg',
    alt: 'Unia Europejska',
  },
];

export const footerLinks = [
  { link: '/onas', name: 'O nas' },
  { link: '/reklama', name: 'Współpraca' },
  { link: '/praca', name: 'Praca' },
  { link: '/kontakt', name: 'Kontakt' },
  { link: '/f/polityka_prywatnosci', name: 'Polityka prywatności' },
  { link: '/logotypy', name: 'Logotypy' },
  { link: '/f/projekty_ue', name: 'Projekty UE' },
  { link: '/f/energia_kultury', name: 'Energia Kultury' },
];
