import { useState, useEffect, useCallback } from 'react';
import { throttle } from 'lodash';

export const useScroll = () => {
  const [scrollPosition, setScrollPosition] = useState({ y: 0, lastScroll: 0 });
  const handleScroll = () => {
    setScrollPosition((prev) => {
      return {
        y: window.scrollY,
        lastScroll: prev.y,
      };
    });
  };
  const debouncedChangeHandler = useCallback(
    throttle(handleScroll, 500)
  , []);

  useEffect(() => {
    window.addEventListener('scroll', debouncedChangeHandler);

    return () => window.removeEventListener('scroll', debouncedChangeHandler);
  }, []);

  return scrollPosition.lastScroll;
};
