export const menuItems = [
  {
    type: 'category',
    name: 'wydarzenia',
    link: '/kategoria/wydarzenia',
  },
  {
    type: 'category',
    name: 'publicystyka',
    link: '/kategoria/publicystyka',
  },
  {
    type: 'category',
    name: 'reportaz',
    link: '/kategoria/reportaz',
  },
  {
    type: 'category',
    name: 'rozrywka',
    link: '/kategoria/rozrywka',
  },
  {
    type: 'category',
    name: 'sport',
    link: '/kategoria/sport',
  },
  {
    type: 'button',
    name: 'ARCHIWUM',
    link: '/archiwum',
    variant: 'outlined',
    nameClass: 'buttonSecondary',
    activeClass: 'activeButtonSecondary',
  },
  {
    type: 'button',
    name: 'NA ŻYWO',
    link: '/player/live',
    variant: 'contained',
    nameClass: 'buttonPrimary',
    activeClass: 'buttonPrimary',
  },
];
