import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://96d16c8c4e904b5383713be1db41baba@o1262825.ingest.sentry.io/6442002',

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
