export const changeTitle = (title) => {
  return title.charAt(0).toUpperCase() + title.slice(1).toLowerCase();
};

export const getHour = (hour) => {
  return hour.split(' ')[1].slice(0, -3);
};

export const splitText = (text) => {
  if (typeof text === 'string') {
    let date = text.replace(/,/g, '');
    date = date.split(' ');
    return date
  }
  else return '';
};

export const getDate = (wholeDate) => {
  return wholeDate.split(' ')[0];
};
