import MaterialButton from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
      white: {
          main: '#ffffff'
      }
    },
  });

const Button = (props) => {
    return (
        <ThemeProvider theme={theme}>
            <MaterialButton {...props} sx={{borderRadius: '32px'}}>{props.value}</MaterialButton>
        </ThemeProvider>
    );
};

export default Button;