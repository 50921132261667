import styles from '../../styles/Footer.module.scss';
import { footerImages, footerLinks } from '../../utils/footerData';
import FooterLogo from './FooterLogo';

const Footer = () => (
  <div className={styles.footerContainer}>
    <div className={styles.footerWrapper}>
      <div className={styles.logoContainer}>
        {footerImages.map((el, idx) => (
          <FooterLogo
            link={el.link}
            img={el.img}
            alt={el.alt}
            key={'footerLogo-' + idx}
          />
        ))}
      </div>
      <div className={styles.line} />
      <div className={styles.linkContainer}>
        {footerLinks.map((el, idx) => (
          <a href={el.link} className={styles.link} key={'footerLink-' + idx}>
            {el.name}
          </a>
        ))}
      </div>
    </div>
  </div>
);

export default Footer;
