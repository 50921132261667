const Error = () => {
  return (
    <div
      style={{ marginTop: '136px', textAlign: 'center' }}
      className={'error'}
    >
      <img src='/404.svg' className='errorImage' />
    </div>
  );
};

export default Error;
