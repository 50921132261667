import React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import styles from '../../styles/ProgramComp.module.scss';
import Carousel from '../Carousel/Carousel';
import Button from '../button/Button';
import dateToString from '../../utils/dateToString';
import { calculateProgramWidth } from '../../utils/calculateWidth';
import { CustomLinear, LiveChip } from './Program.style';
import {
  isFutureProgram,
  calculateProgress,
  convertDay,
} from '../../utils/program';

const Program = React.memo(({ programData, setOpen }) => {
  const router = useRouter();
  const [days, setDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState('');
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [programImageLink, setProgramImageLink] = useState('');
  const [eventsForDay, setEventsForDay] = useState([]);
  const [liveProgram, setLiveProgram] = useState('');
  const [liveProgramId, setLiveProgramId] = useState(0);
  const [progress, setProgress] = useState(0);
  const percentDays = programData && calculateProgramWidth('days');
  const percentHours = programData && calculateProgramWidth('hours');
  const [imgError, setImgError] = useState(false);

  useEffect(() => {
    if (programData && Object.keys(programData).length) {
      setDays(Object.keys(programData));
      const now = new Date();
      const dateString = dateToString(now);
      const currentTimeMs = now.getTime();
      const currentEvent = programData[dateString].events.findIndex((event) => {
        const fromDate = new Date(
          event.from_datetime.replace(' ', 'T')
        ).getTime();
        const toDate = new Date(event.to_datetime.replace(' ', 'T')).getTime();
        if (currentTimeMs >= fromDate && currentTimeMs <= toDate) {
          return event;
        }
      });

      setSelectedDay(dateString);
      if (currentEvent !== -1) {
        const currentEventObj = programData[dateString].events[currentEvent];
        setSelectedProgram(currentEventObj);
        setProgramImageLink(currentEventObj.images[0]);
        setLiveProgram(currentEventObj);
        setLiveProgramId(currentEvent);

        const progress = calculateProgress(currentEventObj);
        setProgress(progress);
      } else {
        setSelectedProgram(programData[dateString].events[0]);
      }
      setEventsForDay(programData[dateString].events);
    }
  }, [programData]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (liveProgram) {
        const progress = calculateProgress(liveProgram);
        if (progress > 100) {
          setLiveProgramId((prevState) => {
            setLiveProgram(eventsForDay[prevState + 1]);

            return prevState + 1;
          });
        } else {
          setProgress(progress);
        }
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [liveProgram]);

  const selectDay = (day) => {
    setSelectedDay(day);
    setEventsForDay(programData[day].events);
    setSelectedProgram(programData[day].events[0]);
  };

  const goToLive = () => {
    router.push('/player/live');
    setOpen(false);
  };

  const redirectToProgram = () => {
    if (selectedProgram.title_url === 'pogoda_dla_lodzi') {
      router.push(`/player/pogoda`);
    } else {
      router.push(`/program/${selectedProgram.title_url}`);
    }
    setOpen(false);
  };

  const checkSelectedItem = () => {
    return eventsForDay.indexOf(selectedProgram);
  };

  const dayChangeHandler = (type) => {
    if (type === 'next' && days.indexOf(selectedDay) !== days.length - 1) {
      selectDay(days[days.indexOf(selectedDay) + 1]);
    } else if (type === 'prev' && days.indexOf(selectedDay) !== 0) {
      selectDay(days[days.indexOf(selectedDay) - 1]);
    }
  };

  return (
    <div className={styles.program}>
      <Paper>
        <Box>
          <div className={styles.daysHoursContainer}>
            <div className={styles.daysCarouselContainer}>
              {days && (
                <Carousel
                  percent={percentDays}
                  showIndicators={false}
                  arrowVariant={'white'}
                  size='small'
                  selectedItem={days.indexOf(selectedDay)}
                  center={true}
                  carouselVariant='days'
                >
                  {days.map((day) => {
                    const dayName = convertDay(new Date(day).getDay());
                    return (
                      <div className={styles.daysContainer} key={day}>
                        <Chip
                          key={day}
                          label={
                            <div>
                              <div className={styles.chipUpperText}>
                                {dayName}
                              </div>
                              <div className={styles.chipLowerText}>{day}</div>
                            </div>
                          }
                          sx={{
                            width: '144px',
                            height: '56px',
                            borderRadius: '64px',
                            background: `${
                              day === selectedDay
                                ? 'rgba(255, 255, 255, 0.25)'
                                : 'transparent'
                            }`,
                            cursor: 'pointer',
                          }}
                          onClick={() => selectDay(day)}
                        />
                      </div>
                    );
                  })}
                </Carousel>
              )}
            </div>

            <Box
              sx={{
                marginBottom: '20px !important',
                maxWidth: '1232px',
                margin: 'auto',
              }}
            >
              <CustomLinear variant='determinate' value={progress} />
            </Box>
            <div className={styles.dayProgramsCarousel}>
              <Carousel
                percent={percentHours}
                showIndicators={false}
                arrowVariant={'white'}
                selectedItem={checkSelectedItem()}
                carouselVariant='hours'
                center
                dayChangeHandler={dayChangeHandler}
              >
                {eventsForDay.map((event) => (
                  <div
                    className={`${styles.hourTab} ${
                      event === selectedProgram
                        ? styles.selectedProgram
                        : styles.unselectedProgram
                    }`}
                    onClick={() => {
                      if (imgError) {
                        setImgError(false);
                      }
                      setSelectedProgram(event);
                    }}
                    key={event.id}
                  >
                    <div className={styles.hourLiveContainer}>
                      <div className={styles.fromName}>{event.from_time}</div>
                      {event === liveProgram && <LiveChip label='NA ŻYWO' />}
                    </div>
                    <div className={styles.programTitle}>{event.title}</div>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>

          <div className={styles.programDescription}>
            {selectedProgram && (
              <img
                src={!imgError ? selectedProgram.images[0] : '/toyaCover.png'}
                className={styles.programImage}
                onError={() => setImgError(true)}
                onClick={redirectToProgram}
              />
            )}
            <div>
              {selectedProgram && (
                <p
                  className={styles.selectedProgramTitle}
                  onClick={redirectToProgram}
                >
                  {selectedProgram.title}
                </p>
              )}
              {selectedProgram && <p>{selectedProgram.description}</p>}
              {selectedProgram === liveProgram ? (
                <div>
                  <Button
                    variant='contained'
                    value='OGLĄDAJ NA ŻYWO'
                    onClick={goToLive}
                    className={styles.buttonPrimary}
                  />
                </div>
              ) : isFutureProgram(selectedProgram) ? (
                <div>
                  <Button
                    variant='contained'
                    value='OGLĄDAJ NA ŻYWO'
                    onClick={goToLive}
                    disabled
                    className={`${styles.buttonPrimary} ${styles.buttonInactive}`}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <Box
            sx={{
              width: '100%',
              height: '16px',
              backgroundColor: '#43A2EE',
            }}
          />
        </Box>
      </Paper>
      <div className={styles.backgroundWrapper} />
    </div>
  );
});

export default Program;
