import styles from '../../styles/Navbar.module.scss';
import { useMobile } from '../../utils/useMobile';

const MenuItem = ({
  menuEl,
  idx,
  activeElement,
  handleActiveElement,
  handleSubmenu,
}) => {
  const { isMobile } = useMobile();

  return (
    menuEl.type === 'category' && (
      <>
        <div key={menuEl.name + idx}>
          <p
            className={activeElement === idx ? styles.activeLink : styles.link}
            onClick={() => {
              handleActiveElement(idx);
              handleSubmenu(menuEl.name);
            }}
          >
            {menuEl.name === 'reportaz' ? 'reportaż' : menuEl.name}
            {activeElement === idx && (
              <img src={'/chevron.svg'} className={styles.chevron} />
            )}
          </p>
        </div>
      </>
    )
  );
};

export default MenuItem;
