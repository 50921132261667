import styles from '../../styles/Layout.module.css';
import Navbar from '../navbar/Navbar';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';

const Layout = ({ children, programData }) => {
  const router = useRouter();
  const [meta, setMeta] = useState([
    {
      title: 'TV Toya',
      description:
        'TELEWIZJA TOYA to telewizja, zgodnie z jej hasłem promocyjnym "...telewizja bliżej Ciebie", bliska zarówno mieszkańcom Łodzi, jak i regionu. Rzetelnie i obiektywnie pokazująca ich sukcesy i problemy.',
      keywords: 'tv toya, łódź',
    },
  ]);

  const route = router.route === '/' ? null : router.route.split('/')[1];
  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      const response = await fetch(`/api/meta/${route}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();

      if (isMounted && response.status === 200) setMeta(data.data);
    };
    fetchData();

    return () => (isMounted = false);
  }, [route]);

  return (
    <>
      <Head>
        <title>{meta[0]?.title}</title>
        <meta name='description' content={meta[0]?.description} />
        <meta name='keywords' content={meta[0]?.keywords} />
      </Head>
      <Navbar programData={programData} />
      <div className={styles.container}>
        <main className={styles.main} key={'main' + children}>
          {children}
        </main>
      </div>
    </>
  );
};

export default Layout;
