import { Chip } from '@mui/material';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import styles from '../../styles/Carousel.module.scss';
import { splitText } from '../../utils/textChanger';

const ProgramCarouselCard = ({ program, length, type = 'submenu', click }) => {
  const [dates, setDates] = useState([]);
  const router = useRouter();

  const goToProgramPage = (programName, standalone) => {
    standalone === 'y'
      ? router.push(`/obejrzyj/${programName}`)
      : programName
      ? router.push(`/program/${programName}`)
      : router.push(`/404`);
    click && click();
  };

  useEffect(() => {
    setDates([...splitText(program.aired_on)]);
  }, [program]);

  return (
    <div
      className={`${
        length > 3
          ? styles.programCard
          : length === 3
          ? styles.mediumProgramCard
          : styles.biggerProgramCard
      } ${type === 'submenu' && styles.submenuProgramCard}`}
      key={program.id}
      onClick={() =>
        goToProgramPage(program.pretty_title_url, program.standalone)
      }
    >
      <div
        className={styles.imageContainer}
        style={{
          backgroundImage: `url(${program.images[0]}), url(/toyaCover.png)`,
        }}
      />
      {type === 'submenu' ? (
        <div className={styles.bottomContainer}>
          <p
            className={`${styles.programCardTitle} ${styles.submenuProgramCardTitle}`}
          >
            {program.title}
          </p>
          <div className={styles.info}>
            {dates?.map(
              (el, idx) =>
                el.length > 0 && (
                  <Chip
                    label={dates[idx]}
                    key={el + idx}
                    color='primary'
                    className={styles.label}
                  />
                )
            )}
          </div>
        </div>
      ) : (
        <p className={styles.programCardTitle}>{program.title}</p>
      )}
    </div>
  );
};

export default ProgramCarouselCard;
