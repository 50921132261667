import '../styles/globals.css';
import '../styles/_fonts.scss';
import '../styles/_vars.scss';

import { useEffect, useState } from 'react';

import { CacheProvider } from '@emotion/react';
import Cookies from '../components/Cookies/Cookies';
import Error from '../components/Error/Error';
import Footer from '../components/Footer/Footer';
import Head from 'next/head';
import Layout from '../components/layout/Layout';
import Script from 'next/script';
import createEmotionCache from '../src/createEmotionCache';
import dateToString from '../utils/dateToString';

const clientSideEmotionCache = createEmotionCache();

function MyApp(props) {
  const [programMenu, setProgramMenu] = useState({});
  useEffect(() => {
    if (props.props.programData) {
      setProgramMenu(props.props.programData.data);
    }
  }, []);
  const { pageProps, Component } = props;
  let error = false;
  if (pageProps && pageProps.statusCode && pageProps.statusCode !== 200)
    error = true;

  return (
    <CacheProvider value={clientSideEmotionCache}>
      <Head>
        <meta
          charset='utf-8'
          name='viewport'
          content='width=device-width, initial-scale=1.0'
        />
      </Head>
      <div>
        <Script strategy='afterInteractive'>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                dataLayer.push(arguments);
            }

            var consent = localStorage.getItem('analytics-config-default-set');
            if (null === consent) {
              var state = {
                ad_storage: 'denied',
                ad_user_data: 'denied',
                ad_personalization: 'denied',
                functionality_storage: 'denied',
                personalization_storage: 'denied',
                security_storage: 'denied',
                analytics_storage: 'denied',
              };

              // Only set default if not previously set
              gtag('consent', 'default', state);

              localStorage.setItem('analytics-config-default-set', 'true');
              localStorage.setItem('analytics-config-consent', JSON.stringify(state));
            } else {
                gtag('consent', 'default', JSON.parse(localStorage.getItem('analytics-config-consent')));
            }
          `}
        </Script>
        <Script
          async
          src='https://www.googletagmanager.com/gtag/js?id=G-Y8XDY8VRGS'
          strategy='afterInteractive'
        ></Script>
        <Script strategy='afterInteractive'>
          {`
            gtag('js', new Date());
    
            gtag('config', 'G-Y8XDY8VRGS');
          `}
        </Script>
      </div>
      {props.props.err === false ? (
        <Layout programData={programMenu ? programMenu : programMenu}>
          {error ? (
            <>
              <Error statusCode={pageProps.statusCode} />
            </>
          ) : (
            <Component {...pageProps} />
          )}
          <Cookies />
          <Footer />
        </Layout>
      ) : (
        <>
          <Error statusCode={props.props.err} />
        </>
      )}
    </CacheProvider>
  );
}

MyApp.getInitialProps = async () => {
  const fromDate = new Date(new Date() - 86400000 * 10);
  let err = false;
  const fromDateString = dateToString(fromDate);
  //
  const response = await fetch(
    `https://webapi.toya.net.pl/v1/tvtoya/programTv?from_date=${fromDateString}&amount=8`,
    {
      method: 'GET',
      mode: 'cors',
      headers: { Authorization: 'Basic dHZ0b3lhOkFZVnVmVUFyR2Y3TWtFTnY=' },
    }
  );
  const data = await response.json();
  if (data.meta.statusCode !== 200) err = true;
  return { props: { programData: data, err: err } };
};

export default MyApp;
