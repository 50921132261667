import { Carousel as CarouselComp } from 'react-responsive-carousel';
import { styled } from '@mui/material/styles';

export const FullscreenCarousel = styled((props) => (
  <CarouselComp {...props} sx={{ marginRight: '40px' }} />
))(() => ({
  '&': {
    paddingBottom: '0 !important',
  },
  '& .control-dots': {
    width: 'auto !important',
    left: '56%',
    transform: 'translateX(-50%)',
    bottom: '60px !important',
    '& .dot': {
      background: '#c4c4c4 !important',
    },
    '.dot.selected': {
      background: '#1D76BB !important',
    },
  },

  '@media(max-width:1365px)': {
    '& .control-dots': {
      left: '50%',
      bottom: '36px !important',
    },
  },
}));

export const RegularCarousel = styled((props) => (
  <CarouselComp key={props.children} {...props} sx={{ marginRight: '40px' }} />
))((props) => {
  let leftArrow, rightArrow, sliderWrapper, sliderMargin;
  switch (props.carouselVariant) {
    case 'withPadding':
      sliderMargin = { desktop: '80px !important', mobile: '8px !important' };
      break;
    case 'onas':
      sliderMargin = {
        desktop: '-17.5% !important',
        laptop: '5% !important',
        tablet:
          props.children.length < 4 ? '50px !important' : '140px !important',
        mobile: '8px !important',
      };
      break;
    case 'programs':
      sliderMargin = {
        desktop:
          props.children.length < 4 ? '-15.5% !important' : '-10.5% !important',
        tablet: props.children.length < 5 ? '6% !important' : '10% !important',
        laptop: props.children.length < 5 ? '9% !important' : '8px !important',
        mobile: '8px !important',
      };
      break;

    case 'watchNow':
      sliderMargin = {
        desktop: '-2.5% !important',
        laptop: '20% !important',
        tablet: '16% !important',
        mobile: '8px !important',
      };
      break;
    case 'withoutDots':
      sliderMargin = {
        desktop: '-11% !important',
        tablet: '15% !important',
        mobile: '60% !important',
      };
      break;
    default:
      sliderMargin = { desktop: '-11% !important', mobile: '8px !important' };
      break;
  }
  if (props.carouselTheme === 'light') {
    leftArrow = {
      content: "url('/darkBlueArrow.svg') !important",
      transform: 'rotate(180deg)',
      height: '24px !important',
    };
    rightArrow = {
      content: "url('/darkBlueArrow.svg') !important",
      height: '24px !important',
    };
    sliderWrapper = {
      margin: '0 auto 32px !important',
    };
  } else if (props.carouselTheme === 'darkBlue') {
    leftArrow = {
      content: "url('/lightBlueArrow.svg') !important",
      height: '40px !important',
      width: '40px !important',
    };
    rightArrow = {
      content: "url('/lightBlueArrow.svg') !important",
      height: '40px !important',
      width: '40px !important',
      transform: 'rotate(180deg)',
    };
    sliderWrapper = {
      margin: '0 auto 32px !important',
    };
  } else if (props.arrowVariant === 'blue') {
    leftArrow = {
      content: "url('/arrow-left.svg') !important",
      height: '24px !important',
    };
    rightArrow = {
      content: "url('/arrow-right.svg') !important",
      height: '24px !important',
    };
    sliderWrapper = {
      margin: '0 auto 32px !important',
    };
  } else {
    leftArrow = {
      content: "url('/whiteArrow.svg') !important",
      transform: 'rotate(90deg)',
    };
    rightArrow = {
      content: "url('/whiteArrow.svg') !important",
      transform: 'rotate(-90deg)',
    };
    sliderWrapper = {
      width: '95% !important',
    };
  }
  let sizeObj = {};
  if (props.size === 'small') {
    sizeObj = {
      height: '100px !important',
    };
  }
  let controlArrowMargin = {};
  if (props.carouselVariant === 'days' || props.carouselVariant === 'hours') {
    controlArrowMargin = {
      marginBottom: '0 !important',
    };
    leftArrow.marginLeft = '-10px !important';
    rightArrow.marginRight = '-10px !important';
    sliderWrapper = {
      width: '90% !important',
    };
  } else if (props.carouselVariant === 'hours') {
    controlArrowMargin = {
      marginBottom: '25px !important',
    };
  } else if (props.carouselVariant === 'programs') {
    controlArrowMargin = {
      marginBottom: '55px !important',
    };
  } else if (props.carouselVariant === 'watchNow') {
    controlArrowMargin = {
      marginBottom: '55px !important',
    };
  } else if (
    props.carouselVariant === 'withoutDots' ||
    props.carouselVariant === 'onas'
  ) {
    controlArrowMargin = {
      marginBottom: '55px !important',
    };
  }

  return {
    '&': sizeObj,
    '& .slider-wrapper': sliderWrapper,
    '& .slider': {
      marginBottom: '24px !important',
      justifyContent: props.flexCenter && 'center',
      marginLeft: sliderMargin.desktop
        ? sliderMargin.desktop
        : props.children.length > 3 && '-11% !important',
      '& li:last-of-type, & li:first-of-type': {
        display: props.infiniteLoop && 'none',
      },
    },
    '& .control-arrow': controlArrowMargin,
    '& .control-dots .dot.selected': {
      background:
        props.theme === 'dark' ? '#43a2ee !important' : '#1D76BB !important',
    },

    '& .control-dots .dot': {
      background:
        props.carouselTheme === 'darkBlue'
          ? '#A7D2F5 !important'
          : '#c4c4c4 !important',
    },
    '&::after,&::before': {
      display: !props.showArrows && 'none !important',
    },

    '& .control-dots': {
      margin: '32px 0 0 !important',
      width: 'auto !important',
      left: '50%',
      transform: 'translateX(-50%)',
    },

    '& .control-prev:before': leftArrow,
    '& .control-next:before': rightArrow,
    '@media(max-width:1365px)': {
      '& .slider': {
        width: '80% !important',
        left: '0 !important',
        marginLeft: sliderMargin.laptop
          ? sliderMargin.laptop
          : props.carouselVariant === 'onas' && props.children.length < 5
          ? '-15% !important'
          : '20% !important',

        marginRight: '8px !important',
        marginBottom: '18px !important',
        '& li:last-of-type': {
          display: props.infiniteLoop && 'none',
        },
      },
    },
    '@media(max-width:979px)': {
      '& .slider': {
        width: '90% !important',
        left: '0 !important',
        marginLeft: sliderMargin.tablet
          ? sliderMargin.tablet
          : props.carouselVariant === 'onas' && props.children.length < 5
          ? '-15% !important'
          : '8% !important',

        marginRight: '8px !important',
        marginBottom: '18px !important',
        '& li:last-of-type': {
          display: props.infiniteLoop && 'none',
        },
      },
    },
    '@media(max-width:760px)': {
      '& .slider': {
        width: '90% !important',
        left: '0 !important',
        marginLeft: sliderMargin.mobile
          ? sliderMargin.mobile
          : '8px !important',

        marginRight: '8px !important',
        marginBottom: '18px !important',
        '& li:last-of-type, & li:first-of-type': {
          display:
            props.infiniteLoop &&
            props.carouselVariant !== 'withoutDots' &&
            'inherit',
        },
      },
      '& .slider-wrapper': {
        width: '100% !important',
        marginBottom: '25px !important',
      },
      '& .control-dots': {
        margin: '0 !important',
      },
    },
  };
});
