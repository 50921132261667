import { styled } from '@mui/material/styles';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import Chip from '@mui/material/Chip';

export const CustomLinear = styled((props) => <LinearProgress {...props} />)(
  () => ({
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#A7D2F5',
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: '#FFDE2E',
    },
  })
);

export const LiveChip = styled((props) => <Chip {...props} />)(() => ({
  '&': {
    background: '#FFDE2E',
    width: '82px',
    height: '24px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '16px',
    marginTop: '20px !important',
    marginLeft: '0 !important',
  },
  '& >span': {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
}));
