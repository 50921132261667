import Collapse from '@mui/material/Collapse';
import styles from '../../styles/Submenu.module.scss';
import { useState, useEffect } from 'react';
import ProgramsCarousel from '../Carousel/ProgramsCarousel/ProgramsCarousel';

const Submenu = ({ open, category, click }) => {
  const [programs, setPrograms] = useState('');

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      const response = await fetch(`/api/submenu/${category}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      if (isMounted) setPrograms(data);
    };
    fetchData();

    return () => (isMounted = false);
  }, [category]);

  return (
    <Collapse in={open}>
      <div className={styles.submenuContainer}>
        {Object.keys(programs).length > 0 && (
          <ProgramsCarousel
            programs={programs.data}
            header={`Programy z kategorii`}
            category={category === 'reportaz' ? 'reportaż' : category}
            type={'submenu'}
            click={click}
          />
        )}
      </div>
      <div className={styles.blackWrapper} />
    </Collapse>
  );
};

export default Submenu;
