import { useMobile } from './useMobile';
import { useEffect, useState } from 'react';

export const calculateWidth = (data, type = 'programs') => {
  const { isMobile, width, screen } = useMobile();
  let percent = 27.5;

  if (screen === 'mobile') {
    type === 'submenu' ? (percent = 67) : (percent = 82);
  }
  if (screen === 'tablet') {
    type === 'watchNow'
      ? (percent = 40)
      : type === 'category'
      ? (percent = 43)
      : (percent = 35);
  }
  if (screen === 'laptop') {
    type === 'watchNow' ? (percent = 44) : (percent = 30);
  }
  if (screen === 'desktop') {
    type === 'watchNow' ? (percent = 27.5) : (percent = 23);
  }
  return percent;
};

export const calculateProgramWidth = (type) => {
  const { isMobile, width } = useMobile();
  const cardWidth = 144;
  let percent;
  if (type === 'hours') {
    percent = isMobile ? (cardWidth / ((70 * width) / 100)) * 100 : 15;
  } else if (type === 'days') {
    percent = isMobile ? (cardWidth / ((80 * width) / 100)) * 100 : 15;
  }

  return percent;
};

export const calculateArchiveCarouselWidth = () => {
  const { isMobile, width, screen } = useMobile();
  const cardWidth = 312;
  const percent =
    screen === 'mobile'
      ? 80
      : screen === 'tablet'
      ? 42
      : screen === 'laptop'
      ? 45
      : 23;
  return percent;
};

export const genericCalculatePercent = (defaultPercent) => {
  const { isMobile, width, screen } = useMobile();
  const cardWidth = 248;
  const percent =
    screen === 'mobile'
      ? 72
      : screen === 'tablet'
      ? 38
      : screen === 'laptop'
      ? 35
      : defaultPercent;

  return percent;
};
