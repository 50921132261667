import React, { useEffect, useState } from 'react';
import { calculateWidth } from '../../../utils/calculateWidth';
import { useMobile } from '../../../utils/useMobile';
import Carousel from '../Carousel';

import ProgramCarouselCard from '../../ProgramCarouselCard/ProgramCarouselCard';

const ProgramsCarousel = ({
  programs = [],
  header = 'Programy z tej kategorii',
  type = 'category',
  category,
  click = () => {},
}) => {
  const { isMobile, isTablet, screen } = useMobile();
  const percent = programs && calculateWidth(programs, type);

  const [data, setData] = useState([]);

  useEffect(() => {
    setData(programs);
  }, [screen, programs]);

  return (
    percent && (
      <Carousel
        header={header}
        theme={type === 'category' ? 'light' : 'darkBlue'}
        flexCenter={programs.length < 4 && !isMobile}
        arrows={isMobile || programs.length > 3}
        percent={percent}
        center={true}
        showIndicators={
          !isTablet && programs.length > 3 && programs.length < 16
        }
        infiniteLoop={screen === 'desktop' || programs.length > 4}
        selectedItem={0}
        carouselVariant={'programs'}
        type={type}
        category={category && category}
      >
        {data.map((el, idx) => (
          <ProgramCarouselCard
            type={type}
            program={el}
            key={el + idx}
            length={programs.length}
            click={click}
          />
        ))}
      </Carousel>
    )
  );
};

export default ProgramsCarousel;
