import { useEffect, useState } from 'react';

import Link from 'next/link';
import styles from '../../styles/Cookies.module.scss';

const Cookies = () => {
    const [isAccepted, setIsAccepted] = useState(null);

    useEffect(() => {
        setIsAccepted(localStorage.getItem('cc_cookie_toya'));
    }, []);

    const acceptAnalytics = () => {
        const state = {
            ad_storage: 'granted',
            ad_user_data: 'granted',
            ad_personalization: 'granted',
            analytics_storage: 'granted',
        };

        window.gtag('consent', 'update', state);
        localStorage.setItem(
            'analytics-config-consent',
            JSON.stringify(state)
        );
        localStorage.setItem('cc_cookie_toya', true);

        setIsAccepted(true);
    };

    const rejectAnalytics = () => {
        const state = {
            ad_storage: 'denied',
            ad_user_data: 'denied',
            ad_personalization: 'denied',
            functionality_storage: 'denied',
            personalization_storage: 'denied',
            security_storage: 'denied',
            analytics_storage: 'denied',
        };

        window.gtag('consent', 'update', state);
        localStorage.setItem(
            'analytics-config-consent',
            JSON.stringify(state)
        );
        localStorage.setItem('cc_cookie_toya', false);

        setIsAccepted(true);
    };

    return (
        <div
            style={{
                display: isAccepted ? 'none' : 'block',
            }}
            className={styles.cookiesContainer}
        >
            <p>
                Strona korzysta z plików cookie. Korzystając z niej zgadzasz
                się na ich przyjmowanie. Zgodę tą możesz wycofać w każdej
                chwili poprzez zmianę ustawień przeglądarki, musisz jednak
                pamiętać, że przetwarzanie które miało miejsce zanim to
                zrobiłeś pozostaje zgodne z prawem.
            </p>
            <div className={styles.buttonsWrapper}>
                <button>
                    <Link href='/f/polityka_cookies'>Dowiedz się więcej</Link>
                </button>
                <button onClick={rejectAnalytics}>
                  Nie akceptuję
                </button>
                <button onClick={acceptAnalytics}>
                  Akceptuję
                </button>
            </div>
        </div>
    );
};

export default Cookies;
