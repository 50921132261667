export const isFutureProgram = (program) => {
  if (program?.from_datetime) {
    const fromDate = new Date(
      program?.from_datetime.replace(' ', 'T')
    ).getTime();
    const nowDate = new Date().getTime();
    return nowDate < fromDate;
  } else return false;
};

export const calculateProgress = (liveEvent) => {
  const fromDate = new Date(liveEvent.from_datetime.replace(' ', 'T'));
  const toDate = new Date(liveEvent.to_datetime.replace(' ', 'T'));
  const programLength = toDate.getTime() - fromDate.getTime();
  const elapsed = Date.now() - fromDate.getTime();
  return (elapsed / programLength) * 100;
};

export const convertDay = (dayId) => {
  switch (dayId) {
    case 0:
      return 'Niedziela';
    case 1:
      return 'Poniedziałek';
    case 2:
      return 'Wtorek';
    case 3:
      return 'Środa';
    case 4:
      return 'Czwartek';
    case 5:
      return 'Piątek';
    case 6:
      return 'Sobota';

    default:
      return '';
  }
};
