import React from 'react';

import styles from '../../styles/Carousel.module.scss';
import ArrowRight from '../../public/whiteArrow.svg';
import { FullscreenCarousel, RegularCarousel } from './Carousel.style';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
const Carousel = ({
  arrows = true,
  center = true,
  percent = 35,
  theme,
  header,
  children,
  autoPlay = false,
  infiniteLoop = false,
  showIndicators = true,
  sideShades = true,
  flexCenter = false,
  arrowVariant = 'blue',
  size = 'normal',
  selectedItem = 0,
  carouselVariant = 'default',
  interval = 5000,
  dayChangeHandler = '',
  category = '',
}) => {
  let classString = `${
    carouselVariant === 'header' ? styles.wrapperHeader : styles.wrapper
  } ${
    sideShades && carouselVariant !== 'days' && carouselVariant !== 'hours'
      ? styles.shades
      : ''
  } ${theme ? styles[theme] : ''}`;

  let x;

  return (
    <>
      {header && (
        <div
          className={
            theme ? `${styles.textContainer} ${styles[theme]}` : styles.wrapper
          }
        >
          <div
            className={
              theme ? `${styles.textWrapper} ${styles[theme]}` : styles.wrapper
            }
          >
            {category ? (
              <p
                className={`${styles.carouselHeader} ${styles.carouselSpanHeader}`}
              >
                {header} <span>{category}</span>
              </p>
            ) : (
              <p className={styles.carouselHeader}>{header}</p>
            )}
          </div>
        </div>
      )}
      {sideShades && <div className={styles.backgroundOnTop}></div>}

      {sideShades && carouselVariant !== 'hours' ? (
        <div
          className={
            carouselVariant === 'withoutDots' || carouselVariant === 'onas'
              ? `${styles.outsideWrapper} ${styles.noDots} ${styles[theme]}`
              : theme
              ? `${styles.outsideWrapper} ${styles[theme]}`
              : styles.wrapper
          }
        >
          <RegularCarousel
            key={children}
            className={classString}
            showStatus={false}
            showArrows={arrows}
            centerMode={center}
            centerSlidePercentage={percent}
            showThumbs={false}
            showIndicators={showIndicators}
            infiniteLoop={infiniteLoop}
            arrowVariant={arrowVariant}
            selectedItem={selectedItem}
            flexCenter={flexCenter}
            size={size}
            swipeScrollTolerance={0}
            verticalSwipe={'standard'}
            carouselVariant={carouselVariant}
            autoPlay={autoPlay}
            carouselTheme={theme}
          >
            {children}
          </RegularCarousel>
        </div>
      ) : carouselVariant === 'hours' ? (
        <div
          className={
            carouselVariant === 'onas'
              ? `${styles.theme}`
              : theme
              ? `${styles.outsideWrapper} ${styles[theme]}`
              : styles.wrapper
          }
        >
          <RegularCarousel
            key={children}
            className={classString}
            showStatus={false}
            showArrows={arrows}
            centerMode={center}
            centerSlidePercentage={percent}
            showThumbs={false}
            showIndicators={showIndicators}
            infiniteLoop={infiniteLoop}
            arrowVariant={arrowVariant}
            selectedItem={selectedItem}
            flexCenter={flexCenter}
            size={size}
            swipeScrollTolerance={0}
            verticalSwipe={'standard'}
            carouselVariant={carouselVariant}
            autoPlay={autoPlay}
            carouselTheme={theme}
            renderArrowPrev={(dec, prevItem) => {
              return (
                <div className={styles.arrowLeft}>
                  <button
                    onClick={() => {
                      if (prevItem) {
                        dec();
                      } else {
                        dayChangeHandler('prev');
                      }
                    }}
                  >
                    <ArrowRight />
                  </button>
                </div>
              );
            }}
            renderArrowNext={(inc, nextItem) => {
              return (
                <div className={styles.arrowRight}>
                  <button
                    onClick={() => {
                      if (nextItem) {
                        inc();
                      } else {
                        dayChangeHandler('next');
                      }
                    }}
                  >
                    <ArrowRight />
                  </button>
                </div>
              );
            }}
          >
            {children}
          </RegularCarousel>
        </div>
      ) : (
        <FullscreenCarousel
          className={classString}
          showStatus={false}
          showArrows={arrows}
          centerMode={center}
          centerSlidePercentage={percent}
          showThumbs={false}
          showIndicators={showIndicators}
          autoPlay={autoPlay}
          infiniteLoop={infiniteLoop}
          interval={interval}
          onSwipeStart={(e) => {
            const { pageY, pageX } = e.changedTouches[0];
            x = [pageY, pageX];
          }}
          onSwipeEnd={(e) => {
            const { pageY, pageX } = e.changedTouches[0];
            const movedVertical = x[0] - pageY;
            const movedHorizontal = x[1] - pageX;
            if (Math.abs(movedVertical) > Math.abs(movedHorizontal) - 300) {
              window.scrollBy({
                top: movedVertical * 4,
                behavior: 'smooth',
              });
            }
          }}
        >
          {children}
        </FullscreenCarousel>
      )}
    </>
  );
};

export default Carousel;
