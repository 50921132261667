import MaterialButton from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import WhiteArrow from '../../public/whiteArrow.svg';
import styles from '../../styles/Navbar.module.scss';
import { useMobile } from '../../utils/useMobile';
import { useScroll } from '../../utils/useScroll';
import Button from '../button/Button';
import Program from '../Program/Program';
import Submenu from '../../components/Submenu/Submenu';
import { menuItems } from '../../utils/menuItems';
import MenuItem from '../MenuItem/MenuItem';

const Navbar = ({ programData }) => {
  const router = useRouter();
  const [searchTerm, setSearchTerm] = useState('');
  const [isClicked, setIsClicked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showNav, setShowNav] = useState(true);
  const [open, setOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [category, setCategory] = useState('wydarzenia');
  const [activeElement, setActiveElement] = useState('');
  const { isMobile, width, isTablet } = useMobile();
  const scroll = useScroll();

  useEffect(() => {
    if (window.scrollY > 90 && window.scrollY > scroll) {
      setShowNav(false);
      setIsOpen(false);
      setIsOpen(false);
      setActiveElement(-1);
    } else {
      setShowNav(true);
    }
  }, [scroll]);

  const handleClick = () => {
    setOpen((prevOpen) => !prevOpen);
    setIsOpen(false);
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const goToSearch = (e) => {
    if ((e.key && e.key === 'Enter') || e.type === 'click') {
      router.push({ pathname: '/archiwum', query: { search: searchTerm } });
      setIsClicked(false);
      setMenuOpen(false);
    }
  };

  const handleSubmenu = (cat) => {
    if (category !== cat) setIsOpen(true);
    else setIsOpen(!isOpen);
    setOpen(false);
    setCategory(cat);
  };

  const handleMobileSubmenu = () => {
    setMenuOpen(false);
    setIsOpen(false);
    setActiveElement(-1);
  };

  const handleActiveElement = (id) => {
    if (activeElement === id) setActiveElement(-1);
    else setActiveElement(id);
  };

  const handleButtons = () => {
    setActiveElement(-1);
    setMenuOpen(false);
    setIsOpen(false);
  };

  return (
    <div className={showNav ? styles.navWrapper : styles.hideNavWrapper}>
      <div
        className={styles.navContainer}
        style={{ columnGap: isMobile ? '0' : '110px' }}
      >
        <Link href={'/'} className={styles.toyaLogoLink}>
          <a
            className={styles.toyaLogoLink}
            onClick={() => {
              handleActiveElement(-1);
              setIsOpen(false);
            }}
          >
            <Image
              src='/logo.svg'
              width={isMobile ? '104px' : '152px'}
              height={isMobile ? '32px' : '48px'}
            />
          </a>
        </Link>
        {isTablet && (
          <img src='/hamburger.svg' onClick={() => setMenuOpen(!menuOpen)} />
        )}
        <div
          className={
            menuOpen ? styles.mobileLinkContainer : styles.linkContainer
          }
        >
          <div className={styles.mobileWrapper} in={menuOpen.toString()}>
            <div className={styles.searchContainer}>
              <Image
                src='/search2.svg'
                width={isTablet ? '70px' : '56px'}
                height='40px'
                className={styles.searchImage}
                onClick={() =>
                  setIsClicked((prevState) => {
                    if (prevState === true && searchTerm) {
                      setIsOpen(false);
                      setActiveElement(-1);
                    }
                    return !prevState;
                  })
                }
              />
              <input
                type='text'
                onChange={handleChange}
                onKeyDown={goToSearch}
                className={
                  isClicked || isTablet ? styles.inputOpen : styles.input
                }
              />
            </div>
            {isTablet && (
              <img src='/x.svg' onClick={() => setMenuOpen(false)} />
            )}
          </div>
          {menuItems.map((menuEl, idx) => (
            <React.Fragment key={menuEl.name + idx}>
              <MenuItem
                menuEl={menuEl}
                activeElement={activeElement}
                idx={idx}
                category={category}
                handleActiveElement={handleActiveElement}
                handleSubmenu={handleSubmenu}
              />
              {isTablet && category === menuEl.name && (
                <Submenu
                  open={isOpen}
                  category={category}
                  click={handleMobileSubmenu}
                />
              )}
            </React.Fragment>
          ))}
          <Link href='/archiwum'>
            <a className={styles.buttonLink}>
              <Button
                variant='outlined'
                color='white'
                value={`ARCHIWUM`}
                className={
                  router.asPath === '/archiwum' ||
                  router.asPath.startsWith('/archiwum?search=')
                    ? `${styles.buttonSecondary} ${styles.activeButtonSecondary}`
                    : styles.buttonSecondary
                }
                disableTouchRipple={true}
                endIcon={<WhiteArrow />}
                onClick={handleButtons}
              />
            </a>
          </Link>
          <span style={{ width: '16px' }} />
          <Link href='/player/live'>
            <a className={styles.buttonLink}>
              <Button
                variant='contained'
                key='ppp'
                value='NA ŻYWO'
                disableTouchRipple={true}
                className={styles.buttonPrimary}
                onClick={handleButtons}
              />
            </a>
          </Link>
        </div>
        {menuOpen && (
          <div className={styles.wrapper} onClick={() => setMenuOpen(false)} />
        )}
        <div className={styles.outsideContent}>
          {!isTablet && (
            <Submenu
              open={isOpen}
              category={category}
              click={handleMobileSubmenu}
            />
          )}
          <>
            <Collapse in={open}>
              <Program programData={programData} setOpen={setOpen} />
            </Collapse>
            <div className={styles.buttonContainer}>
              {!menuOpen && (
                <button onClick={handleClick} className={styles.programButton}>
                  Program
                  <img
                    src='/whiteArrow.svg'
                    className={open ? styles.arrowRotated : ''}
                  />
                </button>
              )}
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
